/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { IoMdClose } from 'react-icons/io';
import { RiArrowDropDownLine } from 'react-icons/ri';
import { RxHamburgerMenu } from 'react-icons/rx';
import { useLocation } from 'react-router-dom';
import Annoucement from './announcement';
import WaitListModal from './mailChimp/wishlistModal';
import CalendlyBooking from './calendly';
import { FaArrowRight } from 'react-icons/fa';
import ContactModal from './contactModal';
import path from 'path';

const Navbar = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { pathname } = useLocation();
  const [isServicesOpen, setServicesOpen] = useState(false);
  const [isResourcesOpen, setResourcesOpen] = useState(false);
  const [isBusinessOpen, setBusinessOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);

  return (
    <div className="fixed w-full z-20">
      <Annoucement />
      <nav
        className={`bg-[#0A2A61] flex justify-between items-center ${
          pathname === '/'
            ? 'text-[white]'
            : pathname === '/business'
            ? 'text-[white]'
            : 'text-[#000000]'
        }`}
        style={{
          backgroundColor:
            pathname === '/'
              ? '#0A2A61'
              : pathname === '/business'
              ? '#085058'
              : '#FFFFFF',
          height: '102px',
          borderBottom:
            pathname === '/'
              ? '2px solid #FFFFFF33'
              : pathname === '/business'
              ? '2px solid #FFFFFF33'
              : '2px solid #F2F2F2',
        }}
      >
        <div className="container mx-auto flex justify-between items-center py-4 px-4 lg:px-0">
          {/* Logo */}
          <div className="text-lg font-bold">
            <a href="/">
              <img
                src={
                  pathname === '/'
                    ? '/asset/images/whiteLogo.svg'
                    : pathname === '/business'
                    ? '/asset/images/whiteLogo.svg'
                    : '/asset/images/blueLogo.svg'
                }
                alt="logo"
              />
            </a>
          </div>

          {/* Desktop Menu */}
          <div className="hidden lg:flex space-x-8 items-center">
            <a
              href="/about"
              className="hover:text-gray-300"
              style={{
                font: '1rem',
                fontWeight: '500',
              }}
            >
              About us
            </a>
            <a
              href="/pricing"
              className="hover:text-gray-300"
              style={{
                font: '1rem',
                fontWeight: '500',
              }}
            >
              Pricing
            </a>

            {/* Services Dropdown */}
            <Menu as="div" className="relative inline-block text-left">
              <Menu.Button
                className="hover:text-gray-300"
                style={{
                  font: '1rem',
                  fontWeight: '500',
                }}
              >
                Services{' '}
                <RiArrowDropDownLine
                  style={{ display: 'inline', fontSize: '1.5rem' }}
                />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute left-0 mt-2 w-40 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dropsize z-10">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="/light-resume"
                          className={`${
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-700'
                          } block px-4 py-2 text`}
                        >
                          Resume Building
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="/auto-apply"
                          className={`${
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-700'
                          } block px-4 py-2 text`}
                        >
                          Auto job Application
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="/light-interview"
                          className={`${
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-700'
                          } block px-4 py-2 text`}
                        >
                          Interview Prep
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="/light-chat"
                          className={`${
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-700'
                          } block px-4 py-2 text`}
                        >
                          AI Companion
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="/copilot"
                          className={`${
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-700'
                          } block px-4 py-2 text`}
                        >
                          Interview Co-pilot
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="/ats-checker"
                          className={`${
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-700'
                          } block px-4 py-2 text`}
                        >
                          ATS Checker
                        </a>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>

            {/* Resources Dropdown */}
            <Menu
              as="div"
              className="relative inline-block text-left"
              style={{
                font: '1rem',
                fontWeight: '500',
              }}
            >
              <Menu.Button className="hover:text-gray-300">
                Resources{' '}
                <RiArrowDropDownLine
                  style={{ display: 'inline', fontSize: '1.5rem' }}
                />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute left-0 mt-2 w-40 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dropsize z-10">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="https://blog.lightforth.org/"
                          className={`${
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-700'
                          } block px-4 py-2 text`}
                        >
                          Blog
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="/faq"
                          className={`${
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-700'
                          } block px-4 py-2 text`}
                        >
                          FAQs
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="/career"
                          className={`${
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-700'
                          } block px-4 py-2 text`}
                        >
                          Career
                        </a>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
            {/* Busines Dropdown */}
            <Menu
              as="div"
              className="relative inline-block text-left"
              style={{
                font: '1rem',
                fontWeight: '500',
              }}
            >
              <Menu.Button className="hover:text-gray-300">
                Business{' '}
                <RiArrowDropDownLine
                  style={{ display: 'inline', fontSize: '1.5rem' }}
                />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-[-50%] mt-2 w-80 origin-top-left bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dropsize2 z-10">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <>
                          <p className="block px-4 py-2 text-[27px] text-gray-700 text-left max-w-[345px]">
                            Help your students land jobs with LightForth
                            Business.
                          </p>
                          <a
                            href="/business"
                            className="block bg-[#0094FC] text-white text-center py-2 px-4 rounded-md mx-4 mt-8 mb-4 hover:bg-[#0A2A61]"
                          >
                            Learn More
                          </a>
                        </>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
            <a
              href="/get-in-touch"
              className="hover:text-gray-300"
              style={{
                font: '1rem',
                fontWeight: '500',
              }}
            >
              Contact us
            </a>
            {pathname !== '/business' && (
              <a
                className={`border  px-4 py-3 transition text block ${
                  pathname === '/'
                    ? 'border-white hover:bg-white hover:text-blue-900'
                    : pathname === '/business'
                    ? 'border-white hover:bg-white hover:text-blue-900'
                    : 'border-[#0A2A61] hover:bg-[#0094FC] hover:text-white hover:border-[#0094FC]'
                }`}
                style={{ borderRadius: '3rem' }}
                href="https://app.lightforth.org/auth-landing"
              >
                Get Started
                {pathname === '/business' && (
                  <FaArrowRight
                    style={{ display: 'inline', marginLeft: '.5rem' }}
                  />
                )}
              </a>
            )}
            {pathname === '/business' && (
              <button
                className="border  px-4 py-3 transition text block border-white hover:bg-white hover:text-blue-900"
                onClick={() => setShow(true)}
                style={{ borderRadius: '3rem' }}
              >
                Get Started
                <FaArrowRight
                  style={{ display: 'inline', marginLeft: '.5rem' }}
                />
              </button>
            )}

            {/* {pathname === '/' && (
              <CalendlyBooking
                className="border  px-4 py-3 transition text block border-white hover:bg-white hover:text-blue-900"
                style={{ borderRadius: '3rem' }}
              />
            )} */}
            {/* {pathname === '/business' && (
              <CalendlyBooking
                className="border  px-4 py-3 transition text block border-white hover:bg-white hover:text-blue-900"
                style={{ borderRadius: '3rem' }}
              />
            )} */}

            {/* )} */}
            {/* {pathname === '/business' && (
              <CalendlyBooking
                className="border  px-4 py-3 transition text block border-white hover:bg-white hover:text-blue-900"
                style={{ borderRadius: '3rem' }}
              />
            )} */}
          </div>

          {/* Mobile Menu Toggle Button */}
          <div className="lg:hidden">
            <button
              onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
              className="text-white focus:outline-none"
            >
              {isMobileMenuOpen ? (
                <IoMdClose className="w-6 h-6" />
              ) : (
                <RxHamburgerMenu
                  className={`w-6 h-6 ${
                    pathname === '/'
                      ? 'text-[white]'
                      : pathname === '/business'
                      ? 'text-[white]'
                      : 'text-[#0A2A61]'
                  }`}
                />
              )}
            </button>
          </div>
        </div>

        {/* Mobile Menu */}
        {isMobileMenuOpen && (
          <div className="absolute top-0 left-0 w-full h-screen bg-[#0A2A61] text-white p-8 flex flex-col space-y-4 mobile-navbar">
            <div className="flex justify-between items-center mb-4">
              <img src="/asset/images/whiteLogo.svg" alt="logo" />
              <button
                onClick={() => setMobileMenuOpen(false)}
                className="text-white focus:outline-none"
              >
                <IoMdClose className="w-8 h-8" />
              </button>
            </div>

            <a href="/about" className="text-lg">
              About us
            </a>
            <a href="/pricing" className="text-lg">
              Pricing
            </a>

            {/* Services Menu */}
            <div>
              <div
                className="flex items-center justify-between cursor-pointer"
                onClick={() => setServicesOpen(!isServicesOpen)}
              >
                <span>Services</span>
                <RiArrowDropDownLine
                  className={`text-2xl transform ${
                    isServicesOpen ? 'rotate-180' : ''
                  }`}
                />
              </div>
              {isServicesOpen && (
                <div className="pl-4 space-y-2">
                  <a className="nav-btn" href="/light-resume">
                    Resume Building
                  </a>
                  <a className="nav-btn" href="/auto-apply">
                    Auto Job Application
                  </a>
                  <a className="nav-btn" href="/light-interview">
                    Interview Prep
                  </a>
                  <a className="nav-btn" href="/light-chat">
                    AI Companion
                  </a>
                  <a className="nav-btn" href="/copilot">
                    Interview Co-pilot
                  </a>
                  <a className="nav-btn" href="/ats-checker">
                    ATS Checker
                  </a>
                </div>
              )}
            </div>

            {/* Resources Menu */}
            <div>
              <div
                className="flex items-center justify-between cursor-pointer"
                onClick={() => setResourcesOpen(!isResourcesOpen)}
              >
                <span>Resources</span>
                <RiArrowDropDownLine
                  className={`text-2xl transform ${
                    isResourcesOpen ? 'rotate-180' : ''
                  }`}
                />
              </div>
              {isResourcesOpen && (
                <div className="pl-4 space-y-2">
                  <a className="nav-btn" href="https://blog.lightforth.org/">
                    Blog
                  </a>
                  <a className="nav-btn" href="/faq">
                    FAQs
                  </a>
                  <a className="nav-btn" href="/career">
                    Career
                  </a>
                </div>
              )}
            </div>

            {/* Business Menu */}
            <div>
              <div
                className="flex items-center justify-between cursor-pointer"
                onClick={() => setBusinessOpen(!isBusinessOpen)}
              >
                <span>Business</span>
                <RiArrowDropDownLine
                  className={`text-2xl transform ${
                    isResourcesOpen ? 'rotate-180' : ''
                  }`}
                />
              </div>
              {isBusinessOpen && (
                <div className="pl-4 space-y-2">
                  <a
                    href="/business"
                    className="block bg-[#0094FC] text-white text-center py-2 px-4 rounded-md mx-4 mt-8 mb-4 hover:bg-[#0A2A61]"
                  >
                    Learn More
                  </a>
                </div>
              )}
            </div>

            <a href="/get-in-touch" className="text-lg">
              Contact us
            </a>
          </div>
        )}
      </nav>
      {/* <WaitListModal
        show={show}
        setShow={setShow}
        show2={show2}
        setShow2={setShow2}
      /> */}
      <ContactModal isOpen={show} onClose={() => setShow(false)} />
    </div>
  );
};
export default Navbar;
