export const color = {
  primary: '#0A2A61',
  secondary: '#000917',
  accent: '#10B981',
  lightBlue: '#0094FC',
  black: '#000000',
  white: '#ffffff',
  business: '#085058',
  // other colors
};

export const countries = [
  { code: 'AF', name: 'Afghanistan', short: 'AFG' },
  { code: 'AL', name: 'Albania', short: 'ALB' },
  { code: 'DZ', name: 'Algeria', short: 'DZA' },
  { code: 'AS', name: 'American Samoa', short: 'ASM' },
  { code: 'AD', name: 'Andorra', short: 'AND' },
  { code: 'AO', name: 'Angola', short: 'AGO' },
  { code: 'AI', name: 'Anguilla', short: 'AIA' },
  { code: 'AQ', name: 'Antarctica', short: 'ATA' },
  { code: 'AG', name: 'Antigua and Barbuda', short: 'ATG' },
  { code: 'AR', name: 'Argentina', short: 'ARG' },
  { code: 'AM', name: 'Armenia', short: 'ARM' },
  { code: 'AW', name: 'Aruba', short: 'ABW' },
  { code: 'AU', name: 'Australia', short: 'AUS' },
  { code: 'AT', name: 'Austria', short: 'AUT' },
  { code: 'AZ', name: 'Azerbaijan', short: 'AZE' },
  { code: 'BS', name: 'Bahamas', short: 'BHS' },
  { code: 'BH', name: 'Bahrain', short: 'BHR' },
  { code: 'BD', name: 'Bangladesh', short: 'BGD' },
  { code: 'BB', name: 'Barbados', short: 'BRB' },
  { code: 'BY', name: 'Belarus', short: 'BLR' },
  { code: 'BE', name: 'Belgium', short: 'BEL' },
  { code: 'BZ', name: 'Belize', short: 'BLZ' },
  { code: 'BJ', name: 'Benin', short: 'BEN' },
  { code: 'BM', name: 'Bermuda', short: 'BMU' },
  { code: 'BT', name: 'Bhutan', short: 'BTN' },
  { code: 'BO', name: 'Bolivia', short: 'BOL' },
  { code: 'BA', name: 'Bosnia and Herzegovina', short: 'BIH' },
  { code: 'BW', name: 'Botswana', short: 'BWA' },
  { code: 'BR', name: 'Brazil', short: 'BRA' },
  { code: 'BN', name: 'Brunei', short: 'BRN' },
  { code: 'BG', name: 'Bulgaria', short: 'BGR' },
  { code: 'BF', name: 'Burkina Faso', short: 'BFA' },
  { code: 'BI', name: 'Burundi', short: 'BDI' },
  { code: 'CV', name: 'Cabo Verde', short: 'CPV' },
  { code: 'KH', name: 'Cambodia', short: 'KHM' },
  { code: 'CM', name: 'Cameroon', short: 'CMR' },
  { code: 'CA', name: 'Canada', short: 'CAN' },
  { code: 'KY', name: 'Cayman Islands', short: 'CYM' },
  { code: 'CF', name: 'Central African Republic', short: 'CAF' },
  { code: 'TD', name: 'Chad', short: 'TCD' },
  { code: 'CL', name: 'Chile', short: 'CHL' },
  { code: 'CN', name: 'China', short: 'CHN' },
  { code: 'CO', name: 'Colombia', short: 'COL' },
  { code: 'KM', name: 'Comoros', short: 'COM' },
  { code: 'CG', name: 'Congo (Brazzaville)', short: 'COG' },
  { code: 'CD', name: 'Congo (Kinshasa)', short: 'COD' },
  { code: 'CR', name: 'Costa Rica', short: 'CRI' },
  { code: 'CI', name: "Côte d'Ivoire", short: 'CIV' },
  { code: 'HR', name: 'Croatia', short: 'HRV' },
  { code: 'CU', name: 'Cuba', short: 'CUB' },
  { code: 'CY', name: 'Cyprus', short: 'CYP' },
  { code: 'CZ', name: 'Czech Republic', short: 'CZE' },
  { code: 'DK', name: 'Denmark', short: 'DNK' },
  { code: 'DJ', name: 'Djibouti', short: 'DJI' },
  { code: 'DM', name: 'Dominica', short: 'DMA' },
  { code: 'DO', name: 'Dominican Republic', short: 'DOM' },
  { code: 'EC', name: 'Ecuador', short: 'ECU' },
  { code: 'EG', name: 'Egypt', short: 'EGY' },
  { code: 'SV', name: 'El Salvador', short: 'SLV' },
  { code: 'GQ', name: 'Equatorial Guinea', short: 'GNQ' },
  { code: 'ER', name: 'Eritrea', short: 'ERI' },
  { code: 'EE', name: 'Estonia', short: 'EST' },
  { code: 'SZ', name: 'Eswatini', short: 'SWZ' },
  { code: 'ET', name: 'Ethiopia', short: 'ETH' },
  { code: 'FJ', name: 'Fiji', short: 'FJI' },
  { code: 'FI', name: 'Finland', short: 'FIN' },
  { code: 'FR', name: 'France', short: 'FRA' },
  { code: 'GA', name: 'Gabon', short: 'GAB' },
  { code: 'GM', name: 'Gambia', short: 'GMB' },
  { code: 'GE', name: 'Georgia', short: 'GEO' },
  { code: 'DE', name: 'Germany', short: 'DEU' },
  { code: 'GH', name: 'Ghana', short: 'GHA' },
  { code: 'GR', name: 'Greece', short: 'GRC' },
  { code: 'GD', name: 'Grenada', short: 'GRD' },
  { code: 'GT', name: 'Guatemala', short: 'GTM' },
  { code: 'GN', name: 'Guinea', short: 'GIN' },
  { code: 'GW', name: 'Guinea-Bissau', short: 'GNB' },
  { code: 'GY', name: 'Guyana', short: 'GUY' },
  { code: 'HT', name: 'Haiti', short: 'HTI' },
  { code: 'HN', name: 'Honduras', short: 'HND' },
  { code: 'HU', name: 'Hungary', short: 'HUN' },
  { code: 'IS', name: 'Iceland', short: 'ISL' },
  { code: 'IN', name: 'India', short: 'IND' },
  { code: 'ID', name: 'Indonesia', short: 'IDN' },
  { code: 'IR', name: 'Iran', short: 'IRN' },
  { code: 'IQ', name: 'Iraq', short: 'IRQ' },
  { code: 'IE', name: 'Ireland', short: 'IRL' },
  { code: 'IL', name: 'Israel', short: 'ISR' },
  { code: 'IT', name: 'Italy', short: 'ITA' },
  { code: 'JM', name: 'Jamaica', short: 'JAM' },
  { code: 'JP', name: 'Japan', short: 'JPN' },
  { code: 'JO', name: 'Jordan', short: 'JOR' },
  { code: 'KZ', name: 'Kazakhstan', short: 'KAZ' },
  { code: 'KE', name: 'Kenya', short: 'KEN' },
  { code: 'KI', name: 'Kiribati', short: 'KIR' },
  { code: 'KP', name: 'North Korea', short: 'PRK' },
  { code: 'KR', name: 'South Korea', short: 'KOR' },
  { code: 'KW', name: 'Kuwait', short: 'KWT' },
  { code: 'KG', name: 'Kyrgyzstan', short: 'KGZ' },
  { code: 'LA', name: 'Laos', short: 'LAO' },
  { code: 'LV', name: 'Latvia', short: 'LVA' },
  { code: 'LB', name: 'Lebanon', short: 'LBN' },
  { code: 'LS', name: 'Lesotho', short: 'LSO' },
  { code: 'LR', name: 'Liberia', short: 'LBR' },
  { code: 'LY', name: 'Libya', short: 'LBY' },
  { code: 'LI', name: 'Liechtenstein', short: 'LIE' },
  { code: 'LT', name: 'Lithuania', short: 'LTU' },
  { code: 'LU', name: 'Luxembourg', short: 'LUX' },
  { code: 'MG', name: 'Madagascar', short: 'MDG' },
  { code: 'MW', name: 'Malawi', short: 'MWI' },
  { code: 'MY', name: 'Malaysia', short: 'MYS' },
  { code: 'MV', name: 'Maldives', short: 'MDV' },
  { code: 'ML', name: 'Mali', short: 'MLI' },
  { code: 'MT', name: 'Malta', short: 'MLT' },
  { code: 'MH', name: 'Marshall Islands', short: 'MHL' },
  { code: 'MR', name: 'Mauritania', short: 'MRT' },
  { code: 'MU', name: 'Mauritius', short: 'MUS' },
  { code: 'MX', name: 'Mexico', short: 'MEX' },
  { code: 'FM', name: 'Micronesia', short: 'FSM' },
  { code: 'MD', name: 'Moldova', short: 'MDA' },
  { code: 'MC', name: 'Monaco', short: 'MCO' },
  { code: 'MN', name: 'Mongolia', short: 'MNG' },
  { code: 'ME', name: 'Montenegro', short: 'MNE' },
  { code: 'MA', name: 'Morocco', short: 'MAR' },
  { code: 'MZ', name: 'Mozambique', short: 'MOZ' },
  { code: 'MM', name: 'Myanmar', short: 'MMR' },
  { code: 'NA', name: 'Namibia', short: 'NAM' },
  { code: 'NR', name: 'Nauru', short: 'NRU' },
  { code: 'NP', name: 'Nepal', short: 'NPL' },
  { code: 'NL', name: 'Netherlands', short: 'NLD' },
  { code: 'NZ', name: 'New Zealand', short: 'NZL' },
  { code: 'NI', name: 'Nicaragua', short: 'NIC' },
  { code: 'NE', name: 'Niger', short: 'NER' },
  { code: 'NG', name: 'Nigeria', short: 'NGA' },
  { code: 'NO', name: 'Norway', short: 'NOR' },
  { code: 'OM', name: 'Oman', short: 'OMN' },
  { code: 'PK', name: 'Pakistan', short: 'PAK' },
  { code: 'PW', name: 'Palau', short: 'PLW' },
  { code: 'PS', name: 'Palestine', short: 'PSE' },
  { code: 'PA', name: 'Panama', short: 'PAN' },
  { code: 'PG', name: 'Papua New Guinea', short: 'PNG' },
  { code: 'PY', name: 'Paraguay', short: 'PRY' },
  { code: 'PE', name: 'Peru', short: 'PER' },
  { code: 'PH', name: 'Philippines', short: 'PHL' },
  { code: 'PL', name: 'Poland', short: 'POL' },
  { code: 'PT', name: 'Portugal', short: 'PRT' },
  { code: 'QA', name: 'Qatar', short: 'QAT' },
  { code: 'RO', name: 'Romania', short: 'ROU' },
  { code: 'RU', name: 'Russia', short: 'RUS' },
  { code: 'RW', name: 'Rwanda', short: 'RWA' },
  { code: 'KN', name: 'Saint Kitts and Nevis', short: 'KNA' },
  { code: 'LC', name: 'Saint Lucia', short: 'LCA' },
  { code: 'VC', name: 'Saint Vincent and the Grenadines', short: 'VCT' },
  { code: 'WS', name: 'Samoa', short: 'WSM' },
  { code: 'SM', name: 'San Marino', short: 'SMR' },
  { code: 'ST', name: 'Sao Tome and Principe', short: 'STP' },
  { code: 'SA', name: 'Saudi Arabia', short: 'SAU' },
  { code: 'SN', name: 'Senegal', short: 'SEN' },
  { code: 'RS', name: 'Serbia', short: 'SRB' },
  { code: 'SC', name: 'Seychelles', short: 'SYC' },
  { code: 'SL', name: 'Sierra Leone', short: 'SLE' },
  { code: 'SG', name: 'Singapore', short: 'SGP' },
  { code: 'SK', name: 'Slovakia', short: 'SVK' },
  { code: 'SI', name: 'Slovenia', short: 'SVN' },
  { code: 'SB', name: 'Solomon Islands', short: 'SLB' },
  { code: 'SO', name: 'Somalia', short: 'SOM' },
  { code: 'ZA', name: 'South Africa', short: 'ZAF' },
  { code: 'SS', name: 'South Sudan', short: 'SSD' },
  { code: 'ES', name: 'Spain', short: 'ESP' },
  { code: 'LK', name: 'Sri Lanka', short: 'LKA' },
  { code: 'SD', name: 'Sudan', short: 'SDN' },
  { code: 'SR', name: 'Suriname', short: 'SUR' },
  { code: 'SE', name: 'Sweden', short: 'SWE' },
  { code: 'CH', name: 'Switzerland', short: 'CHE' },
  { code: 'SY', name: 'Syria', short: 'SYR' },
  { code: 'TW', name: 'Taiwan', short: 'TWN' },
  { code: 'TJ', name: 'Tajikistan', short: 'TJK' },
  { code: 'TZ', name: 'Tanzania', short: 'TZA' },
  { code: 'TH', name: 'Thailand', short: 'THA' },
  { code: 'TL', name: 'Timor-Leste', short: 'TLS' },
  { code: 'TG', name: 'Togo', short: 'TGO' },
  { code: 'TO', name: 'Tonga', short: 'TON' },
  { code: 'TT', name: 'Trinidad and Tobago', short: 'TTO' },
  { code: 'TN', name: 'Tunisia', short: 'TUN' },
  { code: 'TR', name: 'Turkey', short: 'TUR' },
  { code: 'TM', name: 'Turkmenistan', short: 'TKM' },
  { code: 'TV', name: 'Tuvalu', short: 'TUV' },
  { code: 'UG', name: 'Uganda', short: 'UGA' },
  { code: 'UA', name: 'Ukraine', short: 'UKR' },
  { code: 'AE', name: 'United Arab Emirates', short: 'ARE' },
  { code: 'GB', name: 'United Kingdom', short: 'GBR' },
  { code: 'US', name: 'United States', short: 'USA' },
  { code: 'UY', name: 'Uruguay', short: 'URY' },
  { code: 'UZ', name: 'Uzbekistan', short: 'UZB' },
  { code: 'VU', name: 'Vanuatu', short: 'VUT' },
  { code: 'VE', name: 'Venezuela', short: 'VEN' },
  { code: 'VN', name: 'Vietnam', short: 'VNM' },
  { code: 'YE', name: 'Yemen', short: 'YEM' },
  { code: 'ZM', name: 'Zambia', short: 'ZMB' },
  { code: 'ZW', name: 'Zimbabwe', short: 'ZWE' },
];
