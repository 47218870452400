import React from 'react';
import { IoMdClose } from 'react-icons/io';

interface SuccessModalProps {
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  setShow2: React.Dispatch<React.SetStateAction<boolean>>;
  message?: string;
}

const SuccessModal: React.FC<SuccessModalProps> = ({
  setShow,
  setShow2,
  message,
}) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-lg p-6 text-center max-w-md w-full shadow-lg">
        <div className="flex justify-between items-center">
          <img src="/asset/images/blueLogo.svg" alt="logo" />
          <button
            onClick={() => {
              setShow(false);
              setShow2(false);
            }}
          >
            <IoMdClose
              style={{
                fontSize: '1.8rem',
                color: '#4B5563',
                cursor: 'pointer',
              }}
            />
          </button>
        </div>
        <div className="">
          <img
            src="./asset/icons/greenCheck.svg"
            alt="greenCheck"
            style={{
              margin: '1.5rem auto',
            }}
          />
        </div>
        <h2 className="text-2xl mb-2">
          Your form was submitted successfully and our team will reach out to
          you.
        </h2>
        {/* <p className="text-gray-600 mb-6">
          Kindly look out for an email from us.
        </p> */}
        <p className="text-green-600 mb-6">{message}</p>
        <button
          className="absolute top-4 right-4 text-gray-500"
          onClick={() => setShow2(false)}
        >
          &times;
        </button>
      </div>
    </div>
  );
};

export default SuccessModal;
