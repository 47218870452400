import React, { useState, useCallback } from 'react';
import { toast } from 'react-toastify';

interface EmailValidationHookResult {
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  handleEmailChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleEmailValidation: (emailToValidate: string) => Promise<boolean>;
  isValidating: boolean;
  errorMessage: string;
}

const useEmailValidation = (
  BaseURL: string,
  customErrorMessage?: string
): EmailValidationHookResult => {
  const [email, setEmail] = useState('');
  const [isValidating, setIsValidating] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleEmailValidation = useCallback(
    async (emailToValidate: string) => {
      // Reset previous error message
      setErrorMessage('');

      // Skip validation for empty or invalid email format
      if (!emailToValidate || !/\S+@\S+\.\S+/.test(emailToValidate))
        return false;

      try {
        setIsValidating(true);
        const response = await fetch(
          `${BaseURL}/authentication/business-acquisition/verify-email`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              email: emailToValidate,
              token: `2:KY3jZ27t92zuuhZB/)@$b%hECWl7GnAu]XQfr=/6_N'<QTl?q(^fJ#,cUm5rs`,
            }),
          }
        );
        const data = await response.json();
        if (data.response?.emailExist === true) {
          const errorText =
            customErrorMessage ||
            'Email already exists. Please provide another email address.';
          setErrorMessage(errorText);
          // toast.error(errorText);
          return false;
        }
        return true;
      } catch (error) {
        const errorText = 'An error occurred while validating the email.';
        setErrorMessage(errorText);
        toast.error(errorText);
        return false;
      } finally {
        setIsValidating(false);
      }
    },
    [BaseURL, customErrorMessage]
  );

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    // Clear error message when user starts typing again
    setErrorMessage('');
  };

  return {
    email,
    setEmail,
    handleEmailChange,
    handleEmailValidation,
    isValidating,
    errorMessage,
  };
};

export default useEmailValidation;
